import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    modal = null

    connect() {
        document.addEventListener('datatable:reload', (event) => {
            $(`#sg-datatables-${event.detail.selector}`).DataTable().draw();
        });
    }
}