import { Controller } from "@hotwired/stimulus";
import * as tempusDominus from "@eonasdan/tempus-dominus";
import { Namespace } from "@eonasdan/tempus-dominus";
import moment from "moment";

export default class extends Controller {
    static values = {
        config: String,
    };

    connect() {
        const config = JSON.parse(atob(this.configValue));

        this.convertToMomentDate(config, "defaultDate");
        this.convertToMomentDate(config, "date");
        this.convertToMomentDate(config, "viewDate");

        this.convertDatesInArray(config, "disabledDates");
        this.convertDatesInArray(config, "enabledDates");

        this.convertDatesInArray(config, "disabledHours", "HH");
        this.convertDatesInArray(config, "enabledHours", "HH");

        this.convertNestedDatesInArray(
            config,
            "disabledTimeIntervals",
            "HH:mm",
        );
        this.convertNestedDatesInArray(config, "enabledTimeIntervals", "HH:mm");

        if (config.restrictions){
            ["minDate", "maxDate"].forEach((property) => {
                this.convertToMomentDate(config.restrictions, property);
            });
        }

        const picker = new tempusDominus.TempusDominus(this.element, config);
        this.subscribeToChangeEvent(picker);
    }

    convertToMomentDate(object, property) {
        if (object[property]) {
            object[property] = moment(object[property]).toDate();
        }
    }

    convertDatesInArray(object, property, format = "") {
        if (object[property]) {
            object[property] = object[property].map((date) =>
                moment(date, format).toDate(),
            );
        }
    }

    convertNestedDatesInArray(object, property, format = "") {
        if (object[property]) {
            object[property] = object[property].map((interval) =>
                interval.map((time) => moment(time, format).toDate()),
            );
        }
    }

    subscribeToChangeEvent(picker) {
        picker.subscribe(Namespace.events.change, (e) => {
            this.element.dispatchEvent(
                new CustomEvent("change", {
                    bubbles: true,
                    detail: {
                        date: e.date,
                    },
                }),
            );
        });
    }
}
