import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
    static targets = ["timeline"];
    timeline = null;
    items = [];
    static values = {
        steps: Array,
    };

    POPOVER_TOGGLE_SELECTOR = "[data-bs-toggle='popover']";
    INITIALIZED_ATTRIBUTE = "data-kt-initialized";

    buildOptions() {
        return {
            minHeight: "300px",
            order: this.order,
            zoomMax: 1000 * 60 * 60 * 24 * 31 * 2,
            rollingMode: {
                follow: true,
            },
            orientation: {
                axis: "bottom",
                item: "top",
            },
            xss: {
                disabled: true,
            },
            template: (item) => {
                return item.template;
            },
        };
    }

    createTimeline(steps) {
        const self = this;
        this.items = steps;

        this.timeline = new vis.Timeline(
            this.timelineTarget,
            this.items,
            null,
            this.buildOptions(),
        );

        this.timeline.on("click", (properties) => {
            properties.event.preventDefault();
            self.closeExistingPopovers();
            self.handlePopoverClick(properties.event.target);
        });
    }

    closeExistingPopovers() {
        const buttons = document.querySelectorAll(this.POPOVER_TOGGLE_SELECTOR);
        buttons.forEach((button) => {
            const popoverInstance = bootstrap.Popover.getInstance(button);
            if (popoverInstance) {
                this.hidePopoverAndResetAttribute(popoverInstance, button);
            }
        });

        const popover = document.querySelector(".popover");
        if (popover) {
            popover.remove();
        }
    }

    hidePopoverAndResetAttribute(popoverInstance, button) {
        popoverInstance.hide();
        button.setAttribute(this.INITIALIZED_ATTRIBUTE, 0);
    }

    handlePopoverClick(clickedElement) {
        const clickedPopoverButton = clickedElement.closest(
            this.POPOVER_TOGGLE_SELECTOR,
        );
        if (clickedPopoverButton) {
            KTApp.createBootstrapPopover(clickedPopoverButton);
            this.toggleButtonFocus(clickedPopoverButton);

            clickedPopoverButton.addEventListener("shown.bs.popover", () => {
                KTApp.createBootstrapPopovers();
            });
        }
    }

    toggleButtonFocus(button) {
        if (button.getAttribute("aria-describedby")) {
            button.blur();
        } else {
            button.focus();
        }
    }

    updateTimeline(steps) {
        this.closeExistingPopovers();
        this.timeline.destroy();
        this.createTimeline(steps);
    }

    initialize() {
        this.createTimeline(this.stepsValue);

        window.addEventListener("timeline:reload", (event) => {
            this.updateTimeline(event.detail.steps);
        });
    }

    order(a, b) {
        // order by id
        return a.id - b.id;
    }

    zoomIn() {
        this.timeline.zoomIn(0.5);
    }

    zoomOut() {
        this.timeline.zoomOut(0.5);
    }

    move(percentage) {
        const range = this.timeline.getWindow();
        const interval = range.end - range.start;

        this.timeline.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end: range.end.valueOf() - interval * percentage,
        });
    }

    moveLeft() {
        this.move(0.5);
    }

    moveRight() {
        this.move(-0.5);
    }

    toggleRollingMode() {
        this.timeline.toggleRollingMode();
    }
}
