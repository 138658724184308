import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        script: String,
    };

    connect() {
        eval(this.scriptValue);
    }
}
