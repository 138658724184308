import { Controller } from "@hotwired/stimulus";
import * as PDFObject from "pdfobject/pdfobject.min";

export default class extends Controller {
    static values = {
        url: String,
    };

    connect() {
        const options = {
            height: "100%",
            suppressConsole: true,
        };

        PDFObject.embed(this.urlValue, this.element, options);
    }
}
