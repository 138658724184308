import { Controller } from "@hotwired/stimulus";
import Avatar from "avatar-initials";

export default class extends Controller {
    static values = {
        initials: String,
    };

    connect() {
        const initial = this.generateInitials(this.initialsValue);

        this.avatar = Avatar.from(this.element, {
            useGravatar: false,
            initials: initial,
            fontWeight: 400,
            fontFamily: "'Inter', 'Helvetica', 'sans-serif'",
            color: "#fff",
            background: this.colorByInitials(initial),
        });
    }

    generateInitials(initials) {
        const [f, l] = initials.split(" ");
        return [
            f.charAt(0).toUpperCase(),
            (l || "").charAt(0).toUpperCase(),
        ].join("");
    }

    colorByInitials(initials) {
        function map(value, start1, stop1, start2, stop2) {
            return (
                ((value - start1) / (stop1 - start1)) * (stop2 - start2) +
                start2
            );
        }

        const getByteLength = (string) => {
            return new TextEncoder().encode(string[0])[0];
        };

        const minCharByteValue = getByteLength("a");
        const maxCharByteValue = getByteLength("z");

        const minRange = minCharByteValue / maxCharByteValue;
        const maxRange = 1;

        const initialsValue =
            getByteLength(initials[0].toLowerCase()) /
            getByteLength(initials[1].toLowerCase());

        return `hsl(${map(initialsValue, minRange, maxRange, 0, 360)},50%,50%)`;
    }
}
