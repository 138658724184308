import {Controller} from "@hotwired/stimulus";
import {getComponent} from "@symfony/ux-live-component";

export default class extends Controller {
    static targets = [
        "dropzone",
        "input",
        "select",
        "removeAll",
        "items",
        "template",
    ];

    async initialize() {
        this.component = await getComponent(this.element);

        window.addEventListener('dropzone:reset', () => {
            document.querySelectorAll('.dropzone-item').forEach(el => {
                el.remove()
            })

            this.updateScroll()
        });
    }

    connect() {
        this.dropzones = [];
        this.initializeDropzones()

        // this.element.addEventListener('DOMNodeInserted', (event) => {
        //     if (this.dropzoneTargets.length > 0){
        //         this.dropzoneTargets.forEach((element, key) =>
        //             this.handleDropzoneTarget(element, key),
        //         );
        //     }
        // })
    }

    initializeDropzones() {
        this.dropzoneTargets.forEach((element, key) =>
            this.handleDropzoneTarget(element, key),
        );
    }

    handleDropzoneTarget(element, key) {
        const {dataTransfer, dropzoneElement, dropzone} =
            this.initializeDropzone(element, key);

        this.dropzones.push(dropzone);

        this.handleRemoveAllFiles(dropzoneElement, dropzone, dataTransfer);
        this.handleAddedFile(dropzone, dataTransfer, dropzoneElement);
        this.handleFileError(dropzone, dataTransfer, dropzoneElement);
        this.handleRemovedFile(dropzone, dataTransfer, dropzoneElement);
    }

    initializeDropzone(element, key) {
        Dropzone.autoDiscover = false
        if (Dropzone.instances.length > 0) {
            Dropzone.instances.forEach((e) => {
                e.off();
                e.destroy();
            });
        }

        const dataTransfer = new DataTransfer();
        const dropzoneElement = this.createDropzoneElement(element, key);
        const options = this.getDropzoneOptions(dropzoneElement);
        const dropzone = new Dropzone(dropzoneElement.element, options);
        return {dataTransfer, dropzoneElement, dropzone};
    }

    createDropzoneElement(element, key) {
        return {
            element: element,
            input: this.inputTargets[key],
            select: this.selectTargets[key],
            removeAll: this.removeAllTargets[key],
            items: this.itemsTargets[key],
            template: this.templateTargets[key],
            isMultiple: this.inputTargets[key].hasAttribute("multiple"),
        };
    }

    handleRemovedFile(dropzone, dataTransfer, dropzoneElement) {
        dropzone.on("removedfile", (file) => {
            dataTransfer.items.clear();
            dropzone.files.forEach((file) => dataTransfer.items.add(file));

            this.syncFiles(dropzoneElement, dataTransfer);

            if (dropzone.files.length < 1) {
                if (dropzoneElement.removeAll) {
                    dropzoneElement.removeAll.style.display = "none";
                }
            }

            this.updateScroll()
        });
    }

    handleFileError(dropzone, dataTransfer, dropzoneElement) {
        dropzone.on("error", (file) => {
            dataTransfer.items.remove(file);

            this.syncFiles(dropzoneElement, dataTransfer);
        });
    }

    handleAddedFile(dropzone, dataTransfer, dropzoneElement) {
        dropzone.on("addedfile", (file) => {
            dataTransfer.items.add(file);

            this.syncFiles(dropzoneElement, dataTransfer);

            if (dropzoneElement.removeAll) {
                dropzoneElement.removeAll.style.display = "inline-block";
            }

            this.updateScroll()
        });
    }

    updateScroll() {
        const scrollElement = document.querySelector('[data-kt-scroll="true"]');
        const scroll = KTScroll.getInstance(scrollElement);

        scroll.update();
    }

    handleRemoveAllFiles(dropzoneElement, dropzone, dataTransfer) {
        if (dropzoneElement.removeAll) {
            dropzoneElement.removeAll.addEventListener("click", () => {
                Swal.fire({
                    text: "Êtes-vous sûr de vouloir supprimer tous les fichiers ?",
                    icon: "warning",
                    showCancelButton: true,
                    buttonsStyling: false,
                    confirmButtonText: "Oui, supprimez-les !",
                    cancelButtonText: "Non, annuler",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-active-light",
                    },
                }).then((result) => {
                    if (result.value) {
                        dropzoneElement.removeAll.style.display = "none";
                        dropzone.removeAllFiles(true);
                        dataTransfer.items.clear();

                        this.syncFiles(dropzoneElement, dataTransfer);

                        this.updateScroll()
                    } else if (result.dismiss === "cancel") {
                        Swal.fire({
                            text: "Vos fichiers n'ont pas été supprimés !.",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "D'accord, j'ai compris !",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
            });
        }
    }

    syncFiles(dropzoneElement, dataTransfer) {
        dropzoneElement.input.files = dataTransfer.files;

        dropzoneElement.input.dispatchEvent(
            new Event("change", {bubbles: true}),
        );

        this.component
            .set("validatedFields", [], true)
            .then(({response}) => {
            });
    }

    getDropzoneOptions(dropzoneElement) {
        return {
            url: "#",
            autoProcessQueue: false,
            acceptedFiles: "image/png, image/jpg, image/jpeg, application/pdf",
            maxFiles: dropzoneElement.isMultiple ? null : 1,
            maxFilesize: 2, // MB
            parallelUploads: 10,
            autoQueue: false,
            previewTemplate: dropzoneElement.template.parentNode.innerHTML,
            previewsContainer: dropzoneElement.items,
            clickable: dropzoneElement.select,
            dictDefaultMessage: "Déposer des fichiers ici pour télécharger",
            dictFallbackMessage:
                "Votre navigateur ne prend pas en charge les téléchargements de fichiers par glisser-déposer.",
            dictFallbackText:
                "Veuillez utiliser le formulaire de secours ci-dessous pour télécharger vos fichiers comme autrefois.",
            dictFileTooBig:
                "Le fichier est trop volumineux ({{filesize}} Mo). Taille maximale du fichier : {{maxFilesize}} Mo.",
            dictInvalidFileType:
                "Vous ne pouvez pas télécharger de fichiers de ce type.",
            dictResponseError:
                "Le serveur a répondu avec le code {{statusCode}}.",
            dictCancelUpload: "Annuler le téléchargement",
            dictCancelUploadConfirmation:
                "Êtes-vous sûr de vouloir annuler ce téléchargement ?",
            dictRemoveFile: "Effacer le fichier",
            dictMaxFilesExceeded:
                "Vous ne pouvez plus ajouter plus de fichiers.",
        };
    }
}
