import {Controller} from "@hotwired/stimulus";
import Dropzone from "dropzone"

Dropzone.autoDiscover = false

export default class extends Controller {
    static targets = [
        "input",
        "select",
        "removeAll",
        "items",
        "template",
    ]

    connect() {
        this.dataTransfer = new DataTransfer();
        this.dropzone = new Dropzone(this.element, this.getOptions());

        this.bindEvents()

        window.addEventListener('dropzone:reset', () => {
            this.itemsTarget.innerHTML = ""
            this.updateScroll()
        });
    }

    isMultiple() {
        return this.inputTarget.hasAttribute("multiple")
    }

    getOptions() {
        return {
            url: "#",
            autoProcessQueue: false,
            acceptedFiles: "image/png, image/jpg, image/jpeg, application/pdf",
            maxFiles: this.isMultiple() ? null : 1,
            maxFilesize: 2, // MB
            parallelUploads: 10,
            autoQueue: false,
            previewTemplate: this.templateTarget.parentNode.innerHTML,
            previewsContainer: this.itemsTarget,
            clickable: this.selectTarget,
            dictDefaultMessage: "Déposer des fichiers ici pour télécharger",
            dictFallbackMessage:
                "Votre navigateur ne prend pas en charge les téléchargements de fichiers par glisser-déposer.",
            dictFallbackText:
                "Veuillez utiliser le formulaire de secours ci-dessous pour télécharger vos fichiers comme autrefois.",
            dictFileTooBig:
                "Le fichier est trop volumineux ({{filesize}} Mo). Taille maximale du fichier : {{maxFilesize}} Mo.",
            dictInvalidFileType:
                "Vous ne pouvez pas télécharger de fichiers de ce type.",
            dictResponseError:
                "Le serveur a répondu avec le code {{statusCode}}.",
            dictCancelUpload: "Annuler le téléchargement",
            dictCancelUploadConfirmation:
                "Êtes-vous sûr de vouloir annuler ce téléchargement ?",
            dictRemoveFile: "Effacer le fichier",
            dictMaxFilesExceeded:
                "Vous ne pouvez plus ajouter plus de fichiers.",
        };
    }

    bindEvents() {
        this.handleRemoveAllFiles()
        this.handleAddedFile();
        this.handleFileError();
        this.handleRemovedFile();
    }

    handleRemoveAllFiles() {
        if (this.hasRemoveAllTarget) {
            this.removeAllTarget.addEventListener("click", () => {
                Swal.fire({
                    text: "Êtes-vous sûr de vouloir supprimer tous les fichiers ?",
                    icon: "warning",
                    showCancelButton: true,
                    buttonsStyling: false,
                    confirmButtonText: "Oui, supprimez-les !",
                    cancelButtonText: "Non, annuler",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-active-light",
                    },
                }).then((result) => {
                    if (result.value) {
                        this.removeAllTarget.style.display = "none";
                        this.dropzone.removeAllFiles(true);
                        this.dataTransfer.items.clear();

                        this.syncFiles()
                    } else if (result.dismiss === "cancel") {
                        Swal.fire({
                            text: "Vos fichiers n'ont pas été supprimés !.",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "D'accord, j'ai compris !",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
            });
        }
    }

    syncFiles() {
        this.inputTarget.files = this.dataTransfer.files;

        this.inputTarget.dispatchEvent(
            new Event("change", {bubbles: true}),
        );

        this.updateScroll()
    }

    handleAddedFile() {
        this.dropzone.on("addedfile", (file) => {
            this.dataTransfer.items.add(file);

            this.syncFiles();

            if (this.hasRemoveAllTarget) {
                this.removeAllTarget.style.display = "inline-block";
            }
        });
    }

    handleFileError() {
        this.dropzone.on("error", (file) => {
            this.dataTransfer.items.remove(file);

            this.syncFiles();
        });
    }

    handleRemovedFile() {
        this.dropzone.on("removedfile", (file) => {
            this.dataTransfer.items.clear();
            this.dropzone.files.forEach((file) => this.dataTransfer.items.add(file));

            this.syncFiles();

            if (this.dropzone.files.length < 1) {
                if (this.hasRemoveAllTarget) {
                    this.removeAllTarget.style.display = "none";
                }
            }
        });
    }

    updateScroll() {
        const scrollElements = document.querySelectorAll('[data-kt-scroll="true"]');

        scrollElements.forEach((element) => {
            const scroll = KTScroll.getInstance(element);
            scroll.update();
        });
    }
}