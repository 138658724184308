import "jquery";
import "@/src/plugins/cascader/cascader.js";
import "@/src/plugins/cascader/cascader.css";

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "target"];
    static values = {
        choices: Array,
        searchable: Boolean,
        checkStrictly: Boolean,
        rangeSeparator: String,
        valueKey: String,
        labelKey: String,
        childrenKey: String,
    };

    initialize() {
        const self = this;

        // Get the current value of the target input
        const currentValue = this.targetTarget.value;

        $(this.inputTarget).zdCascader({
            data: this.choicesValue,
            search: this.searchableValue,
            checkStrictly: this.checkStrictlyValue,
            range: this.rangeSeparatorValue,
            valueKey: this.valueKeyValue,
            labelKey: this.labelKeyValue,
            childrenKey: this.childrenKeyValue,
            defaultValue: currentValue ? currentValue : null,
            onChange: function (el, data) {
                self.targetTarget.value = data[self.valueKeyValue];

                // trigger change event with bubbles
                const event = new Event("change", { bubbles: true });
                self.targetTarget.dispatchEvent(event);
            },
        });
    }
}
