import { Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["address"]

    connect() {
        this.initializeMap()
    }

    initializeMap() {
        this.autocomplete()
        console.log('init')
    }

    autocomplete() {
        if (this._autocomplete === undefined) {
            this._autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
            this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
            this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
        }
        return this._autocomplete
    }

    locationChanged() {
        let place = this.autocomplete().getPlace();

        const formattedAddress = place.address_components.reduce((accumulator, component) => {
            return accumulator + (accumulator ? ', ' : '') + component.long_name;
        }, '');

        this.addressTarget.value = formattedAddress
        this.addressTarget.dispatchEvent(new Event('change', {bubbles: true}))
    }
}