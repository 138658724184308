import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        authorId: String
    }

    connect() {
        this.adjustDisplay();
        this.element.parentElement.scrollTop = this.element.parentElement.scrollHeight
    }

    adjustDisplay() {
        const userId = this.element.parentElement.dataset.userId;
        const isOutgoing = userId === this.authorIdValue;

        // Define classes based on whether the message is outgoing
        const justifyClass = isOutgoing ? "justify-content-end" : "justify-content-start";
        const alignClass = isOutgoing ? "align-items-end" : "align-items-start";
        const textClass = isOutgoing ? "text-end" : "text-start";
        const backgroundClass = isOutgoing ? "bg-light-primary" : "bg-light-info";
        const reverseClass = isOutgoing ? "flex-row-reverse" : "__";
        const flexClass = isOutgoing ? "d-flex" : "__";
        const centerClass = isOutgoing ? "align-items-center" : "__";

        // Apply classes to elements
        this.element.classList.add(justifyClass);
        this.element.querySelector(".flex-column").classList.add(alignClass);
        this.element.querySelector('[data-kt-element="message-text"]')?.classList.add(textClass, backgroundClass);
        this.element.querySelector('.align-items-center').classList.add(reverseClass);
        this.element.querySelector('.ms-3').classList.add(flexClass, reverseClass, centerClass);

        // Adjust margins
        this.element.querySelector(".ms-3").classList.replace("ms-3", "me-3");
        this.element.querySelector(".me-1").classList.replace('me-1', "ms-1");

        // Change author name to "Vous" if the user is the author
        if (isOutgoing) {
            this.element.querySelector('#author').textContent = 'Vous';
            this.element.querySelector('#timestamp').classList.replace("mb-1", "mb-0");
        }
    }
}

