import {Controller} from "@hotwired/stimulus";

import { Popover } from 'bootstrap';

export default class extends Controller {
    popover = null
    connect() {
        this.popover = this.element.closest('.popover');
        document.addEventListener('confirm-delete:succeeded', (event) => {
            this.popover.remove()
        });
    }
}