import { Controller } from "@hotwired/stimulus";
import { getComponent } from "@symfony/ux-live-component";

export default class extends Controller {
    static targets = ["stepperElement"];

    async initialize() {
        this.liveComponent = await getComponent(this.element);
        this.valueStore = this.liveComponent.valueStore;
        this.liveComponent.on("render:started", (html, { response }) => {
            this.canGoNext = response.status === 200;
        });
    }

    connect() {
        this.stepper = new KTStepper(this.stepperElementTarget);
        this.canGoNext = false;
        this.stepper.on("kt.stepper.next", () =>
            this.performActionOnStepperNext(),
        );
        this.stepper.on("kt.stepper.previous", () =>
            this.performActionOnStepperPrevious(),
        );
        this.stepper.on("kt.stepper.change", () => (this.canGoNext = false));
    }

    performActionOnStepperNext() {
        if (this.canGoNext) {
            this.stepper.goNext();
            KTUtil.scrollTop();
        } else {
            const content = this.stepper.getStepContent();
            const formFields = this.getFields(content);
            const uniquePropertyNames = this.getUniquePropertyNames(formFields);
            this.liveComponent
                .set("validatedFields", uniquePropertyNames, true)
                .then(({ response }) => {
                    if (response.status === 200) {
                        this.stepper.btnNext.removeAttribute("disabled");
                        this.stepper.goNext();
                        KTUtil.scrollTop();
                    } else {
                        this.stepper.btnNext.setAttribute(
                            "disabled",
                            "disabled",
                        );
                    }
                });
        }
    }

    // method to get unique property names
    getUniquePropertyNames(formFields) {
        return new Set(
            [...formFields]
                .map((field) => this.getPropertyNameMappedByProps(field))
                .filter((propertyName) => propertyName !== null),
        );
    }

    performActionOnStepperPrevious() {
        this.stepper.goPrevious();
        KTUtil.scrollTop();
    }

    getFields(content) {
        return content.querySelectorAll("input, select, textarea");
    }

    getPropertyNameMappedByProps(field) {
        const props = this.valueStore.props;
        const fieldName = field.getAttribute("name");
        const formName = props.formName;
        if (fieldName) {
            const fieldNameWithoutFormName = fieldName
                .replace(formName + "[", "")
                .slice(0, -1);
            const propertyNameParts = fieldNameWithoutFormName.split("][");
            return (
                formName +
                "." +
                propertyNameParts.filter((part) => part !== "").join(".")
            );
        }
        return null;
    }
}
