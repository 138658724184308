import {Controller} from '@hotwired/stimulus';
import toast from '@/umbrella/ui/Toast';

/**
 * Allows you to dispatch a "modal:close" JavaScript event to close it.
 *
 * This is useful inside a LiveComponent, where you can emit a browser event
 * to open or close the modal.
 *
 * See templates/components/BootstrapModal.html.twig to see how this is
 * attached to Bootstrap modal.
 */
export default class extends Controller {

    initialize() {
        document.addEventListener('toast:show', (event) => {
            const { type, text, title, options } = event.detail
            toast.show(type, text, title, options)
        });
    }
}
