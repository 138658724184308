import { startStimulusApp } from "@symfony/stimulus-bridge";
import TreeController from "@kanety/stimulus-tree";

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
const app = startStimulusApp(
    require.context(
        "@symfony/stimulus-bridge/lazy-controller-loader!./controllers",
        true,
        /\.[jt]sx?$/,
    ),
);

app.register("tree", TreeController);

export { app };