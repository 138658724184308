import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';

/**
 * Allows you to dispatch a "modal:close" JavaScript event to close it.
 *
 * This is useful inside a LiveComponent, where you can emit a browser event
 * to open or close the modal.
 *
 * See templates/components/BootstrapModal.html.twig to see how this is
 * attached to Bootstrap modal.
 */
export default class extends Controller {
    static targets = ["offcanvas"];
    offcanvas = null;

    connect() {
        this.offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasTarget);

        console.log(this.offcanvas);

        document.addEventListener("hidden.bs.offcanvas", () => {
            this.offcanvas.hide();
        });
    }

    show() {
        this.offcanvas.show();
    }

    hide() {
        this.offcanvas.hide();
    }
}
